<template>
  <div class="servers-table">
    <div v-for="server, idx of $store.index.servers" :key="`server-${server.id}`" class="flex-col gap-4">
      <div class="server">
        <div class="flex-col gap-1">
          <p class="hostname">{{ server.hostname }}</p>
          <div>
            IP: <span class="info">{{ server.address }}</span>
            <button type="button" class="copy" @click="copy(server)">
              <Icon name="lucide:copy" />
            </button>
          </div>
        </div>

        <div class="flex-row gap-4 align-c justify-c">
          <div v-if="server.isOnline" class="flex-col gap-1 align-c">
            <p>Map: <span class="info">{{ server.map || 'Map name' }}</span></p>
            <p>Players: <span class="info">{{ server.players || 0 }}/{{ server.maxPlayers || 0 }}</span></p>
          </div>
          <div v-else>
            <p>Server is offline</p>
          </div>
          <NuxtLink
            :to="join[idx]"
            class="flex-col connect"
            target="_blank"
            rel="nofollow"
          >
            JOIN
          </NuxtLink>
        </div>
      </div>

      <div v-if="expanded === idx" class="stats-wrapper flex-col gap-2">
        <div v-if="isLoading" class="loader-wrapper">
          <app-loader />
        </div>
        <template v-else>
          <!-- * --- * SUSPENSION STATISTICS * --- * -->
          <div class="statistics">
            <div class="statistic banned">
              <p>Players banned</p>
              <div>
                <div>
                  <p>Total</p>
                  <p>{{ serverStats[idx].bans.total }}</p>
                </div>
                <div>
                  <p>Active</p>
                  <p>{{ serverStats[idx].bans.active }}</p>
                </div>
              </div>
            </div>

            <div class="statistic muted">
              <p>Players muted</p>
              <div>
                <div>
                  <p>Total</p>
                  <p>{{ serverStats[idx].muted.total }}</p>
                </div>
                <div>
                  <p>Active</p>
                  <p>{{ serverStats[idx].muted.active }}</p>
                </div>
              </div>
            </div>

            <div class="statistic warned">
              <p>Players warned</p>
              <div>
                <div>
                  <p>Total</p>
                  <p>{{ serverStats[idx].warned.total }}</p>
                </div>
                <div>
                  <p>Active</p>
                  <p>{{ serverStats[idx].warned.active }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- * --- * PLAYER COUNT GRAPH * --- * -->
          <div v-if="chart" class="chart-wrapper">
            <p>Player count chart</p>
            <ClientOnly>
              <vue-apex-charts
                type="area"
                :height="350"
                :options="chart.options"
                :series="chart.series"
              />
            </ClientOnly>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts';
import type { IServer, IServerStatistics } from '../types/types';

const { $store, $api, $toast } = useNuxtApp();
const { t } = useI18n();

const expanded = ref(-1);
const serverStats = reactive<{ [id: number]: IServerStatistics }>({});
const isLoading = ref(false);

const join = computed(() => {
  return $store.index.servers.map(server => `steam://connect/${server.address}`);
});

const chart = computed(() => {
  if (expanded.value === -1 || !serverStats[expanded.value]) {
    return null;
  }
  return {
    series: [
      { data: serverStats[expanded.value].playerCounts.map(pc => ([ pc.time, pc.players ])), name: 'Players' },
    ],
    options: helpers.chart.areaChart(
      'Server player count',
      {
        min: 0,
        max: Math.min(16, Math.max(...serverStats[expanded.value].playerCounts.map(pc => pc.players)) + 1),
      },
    ),
  };
});

onMounted(async () => {
  expanded.value = 0;
  isLoading.value = true;
  serverStats[0] = await $api.servers.getServerStats(1);
  isLoading.value = false;
});

function copy(server: IServer) {
  navigator.clipboard.writeText(server.address);
  $toast.info(t('clipboard'), t('serverIpCopied'));
}
</script>

<style scoped lang="sass">
.servers-table
  margin: 3rem 3rem 2rem
  display: flex
  flex-direction: column
  gap: 2rem
  color: #bbb
  font-size: 1.4rem

  .server
    border: 2px solid #99c
    border-radius: 2rem
    padding: 1.5rem
    display: flex
    justify-content: space-between

    .hostname
      font-size: 1.6rem
      font-weight: bold
      color: #ffaa00

    .info
      font-size: 1.4rem
      color: #fff

  .stats-wrapper
    padding: 0 2rem

  .statistics
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr))
    gap: 2rem
    .statistic
      display: flex
      flex-direction: column
      padding: 2rem
      gap: 1rem
      & > p
        font-size: 2.4rem
        font-weight: 500
        width: fit-content
        padding-left: 1rem
      & > div
        display: flex
        border: 2px solid #bbb
        border-radius: 2rem
        padding: 2rem
        gap: 3rem
        p:first-of-type
          color: #fff
          font-size: 1.6rem
          font-weight: 500
        p:last-of-type
          font-size: 3rem
          font-weight: 200
        & > div
          width: 50%
          border-radius: 1rem
          padding: 1rem 1.5rem
          &:first-child
            background: #3357
            color: #ffaa00
      &.banned > div > div:last-child
        background: #8334
        color: #e55
      &.muted > div > div:last-child
        background: #3934
        color: #5f5
      &.warned > div > div:last-child
        background: #fa33
        color: #fb7

  .chart-wrapper
    p
      font-size: 2.4rem
      font-weight: 500
      width: fit-content
      padding-left: 3rem

.connect
  background: #ffaa00
  color: #000
  border-radius: 1rem
  padding: 1rem 2rem
  font-weight: 600
  justify-content: center
  cursor: pointer
  transition: all .2s
  height: 100%
  &:hover
    background: #ffaa00aa

.copy
  cursor: pointer
  margin-left: 1rem
  color: #fff
  transition: all .1s
  &:hover
    color: #ffaa00
</style>
